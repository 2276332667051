var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"appear",rawName:"v-appear"},{name:"aware",rawName:"v-aware"}],ref:"root",staticClass:"home-organizers-card-episodes",on:{"viewport":_vm.onResize}},[_c('div',{staticClass:"home-organizers-card-episodes-inner wrapper"},[(!Array.isArray(_vm.content) && _vm.content.title)?_c('div',{staticClass:"home-organizers-card-episodes__title h3",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e(),(!Array.isArray(_vm.content) && _vm.content.intro)?_c('div',{staticClass:"home-organizers-card-episodes__intro wysiwyg mt-s",domProps:{"innerHTML":_vm._s(_vm.content.intro)}}):_vm._e(),_c('div',{staticClass:"content-outer wrapper"},[_c('div',{staticClass:"content"},[_c('div',{ref:"list",staticClass:"slider"},_vm._l((Array.isArray(_vm.content)
              ? _vm.content
              : _vm.content.episodes),function(video,i){return _c('Episode',{key:video.title,ref:"episodes",refInFor:true,staticClass:"video",attrs:{"tag":"li","content":video,"index":i,"current":i === _vm.index}})}),1),_c('div',{staticClass:"buttons"},[_c('GAction',{ref:"prevButton",staticClass:"button",attrs:{"content":{
              label: 'Précédent',
              tag: 'button',
              modifiers: ['no-label'],
              icon: 'leftArrow',
            },"aria-label":"Précédent","disabled":_vm.index === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('GAction',{ref:"nextButton",staticClass:"button",attrs:{"content":{
              label: 'Suivant',
              tag: 'button',
              modifiers: ['no-label'],
              icon: 'arrow',
            },"aria-label":"Suivant","disabled":!_vm.canSlide},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }