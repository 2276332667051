







































































import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import { Episodes } from '@/inc/types'

import Episode from '@/components/home-organizers/Episode.vue'

export default defineComponent({
  name: 'HomeOrganizersHero',

  props: {
    content: {
      type: [Array, Object] as PropType<Episodes>,
      required: true,
    },
  },

  components: {
    Episode,
  },

  setup() {
    const list = ref<null | HTMLElement>(null)
    const total = ref(0)
    const current = ref(0)
    const prevButton = ref()
    const nextButton = ref()
    const canSlide = ref(true)
    const index = ref(0)
    const isMobile = ref(false)
    const episodes = ref<InstanceType<typeof Episode>[] | null>(null)
    let flickity

    onMounted(async () => {
      isMobile.value = window.innerWidth <= 1024

      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      flickity = new Flickity(list.value as HTMLElement, {
        cellSelector: '.video',
        pageDots: false,
        prevNextButtons: false,
        cellAlign: 'left',
        wrapAround: false,
        contain: true,
        draggable: isMobile.value,
      })

      const updateProgress = (i, total) => {
        index.value = i

        if (i >= total - 1) {
          canSlide.value = false
        } else {
          canSlide.value = true
        }
      }

      total.value = flickity.cells.length
      current.value = flickity.selectedIndex

      flickity.on('change', () => {
        episodes.value![index.value].pauseVideo()
        episodes.value![flickity.selectedIndex].playVideo()
        updateProgress(flickity.selectedIndex, flickity.cells.length)
      })

      flickity.on('dragStart', () => {
        if (list.value) {
          list.value.classList.add('is-dragging')
        }
      })
      flickity.on('dragEnd', () => {
        if (list.value) {
          list.value.classList.remove('is-dragging')
        }
      })
    })

    const onResize = (width: number) => {
      const newIsMobile = width <= 1024

      if (isMobile.value !== newIsMobile) {
        isMobile.value = newIsMobile
      }

      isMobile.value = newIsMobile
    }

    const prev = () => {
      flickity.previous()
    }

    const next = () => {
      flickity.next()
    }

    return {
      list,
      prev,
      next,
      prevButton,
      nextButton,
      canSlide,
      index,
      onResize,
      isMobile,
      episodes,
    }
  },
})
